<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <img src="@/assets/logo.svg" style="background-color:black">
        <h3>Formular za prijavljivanje negiranja genocida</h3>        
        <router-link to="/formular">Prijavite negiranje genocida</router-link>
      </div> 
      </div> 
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home'
}
</script>
