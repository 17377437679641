<template>
  <div class="content">
    
  <div id="entry-form" class="md-layout md-size-100 md-gutter" data-background-color="green">
    <form action="" @submit.prevent="add_cin">
      <div class="md-layout-item md-size-100">
        <img src="@/assets/logo.svg" style="background-color:black" class="img-fluid">
        <h3>Formular za prijavljivanje činova negiranja genocida</h3>
        
      </div> 
      <div class="md-layout-item md-alignment-top-center md-size-100">
        <md-toolbar :md-elevation="1" md-xlarge-size-33>
          <span class="md-title">Čin negiranja</span>
        </md-toolbar>

        <md-field>
          <input v-model.lazy="working_entry.cin.cin_naslov" class="md-input" type="text" required placeholder="Naslov">
        </md-field>

        <md-field>
          <input v-model.lazy="working_entry.cin.cin_link" class="md-input" type="text" required placeholder="Link">
        </md-field>

        <md-field>
          <input v-model.lazy="working_entry.cin.cin_citat_opis" class="md-input" type="text" required placeholder="Opis">
        </md-field>

        <md-datepicker v-model="working_entry.cin.cin_datum" required :md-model-type="Number">
          <label>Datum Negiranja</label>
        </md-datepicker>

        <md-field>
          <label for="nacin">Nacin negiranja</label>
          <md-select
            v-model.lazy="working_entry.cin.cin_nacin_negiranja"
            name="nacin"
            id="nacin"
            placeholder="Nacin negiranja"            
          >
            <md-option value="Prizivanje novog genocida"
              >Prizivanje novog genocida</md-option
            >
            <md-option value="Trijumfalizam - slavljenje genocida koji se desio"
              >Trijumfalizam - slavljenje genocida koji se desio</md-option
            >
            <md-option value="Aktivno poricanje / negiranje"
              >Aktivno poricanje / negiranje</md-option
            >
            <md-option value="Priznavanje zločina ali ne i genocida"
              >Priznavanje zločina ali ne i genocida</md-option
            >
            <md-option
              value="Relativizacija (opravdavanje i/ili poređenje sa drugim ratnim zločinima)"
              >Relativizacija (opravdavanje i/ili poređenje sa drugim ratnim
              zločinima)</md-option
            >
            <md-option
              value="Podrška počiniteljima (ne mora se eksplicitno spominjati genocid)"
              >Podrška počiniteljima (ne mora se eksplicitno spominjati
              genocid)</md-option
            >
            <md-option value="Odbijanje / ignorisanje teme"
              >Odbijanje / ignorisanje teme</md-option
            >
          </md-select>
        </md-field>

        <md-field>
          <label for="nacin">Država</label>
          <md-select
            v-model.lazy="working_entry.cin.cin_drzava"
            name="cin_drzava"
            id="cin_drzava"
            placeholder="Država"
            
          >
            <md-option value="Bosna i Hercegovina"
              >Bosna i Hercegovina</md-option
            >
            <md-option value="Bosna i Hercegovina - RS"
              >Bosna i Hercegovina - RS</md-option
            >
            <md-option value="Bosna i Hercegovina - FBiH"
              >Bosna i Hercegovina - FBiH</md-option
            >
            <md-option value="Bosna i Hercegovina - BD"
              >Bosna i Hercegovina - BD</md-option
            >
            <md-option value="Republika Srbija">Republika Srbija</md-option>
            <md-option value="Crna Gora ili Hrvatska"
              >Crna Gora ili Hrvatska</md-option
            >
            <md-option value="Ostale države iz regije"
              >Ostale države iz regije</md-option
            >
            <md-option value="Ostale države">Ostale države</md-option>
          </md-select>
        </md-field>

        <md-divider></md-divider>
      </div>

      <div class="md-layout-item md-alignment-top-center md-size-100" v-if="negator_data_fetched">
        <md-toolbar :md-elevation="1">
          <span class="md-layout-item md-size-100 md-title md-aligment-top-left" style="text-align:left; padding-top:5px">Podaci o negatoru</span>
          <div class="md-layout-item md-size-33">
          
            <md-field style="margin-left: 10px; margin-right:10px;">
                  <label for="rod" 
                    >Odaberite negatora</label
                  >
                  <md-select v-model.lazy="working_entry.cin.cin_uuid_negator">
                    
                    <md-option
                      v-for="item in negators_list"
                      v-bind:key="item.id"
                      v-bind:value="item.id"
                      >{{ item.negator_ime_naziv }}</md-option
                    >
                  </md-select>
            </md-field>
            
          </div>
          <div class="md-layout-item md-size-33">
            <md-switch v-model.lazy="akter_locked" disabled >
              <label v-if="akter_locked">Editovanje je trenutno ugašeno</label
              ><label v-else>Editovanje je trenutno upaljeno</label></md-switch
            >
          </div>
          <div class="md-layout-item md-size-33">
            <md-button
              class="md-dense md-raised md-primary"
              v-on:click="add_new_negator"
              >Dodaj novog negatora</md-button
            >
          </div>
        </md-toolbar>

        <div class="md-layout-item md-size-100">
          <md-field>
          <input v-model.lazy="working_entry.negator.negator_ime_naziv" class="md-input" type="text" required placeholder="Ime Negatora" :disabled="akter_locked">          
          </md-field>
        </div>

        <div class="md-layout-item md-size-100">
          <md-field>
            <input v-model.lazy="working_entry.negator.negator_opis" class="md-input" type="text" required placeholder="Opis Negatora" :disabled="akter_locked">          
          </md-field>
        </div>
        <div class="md-layout-item md-size-100">
          <md-field>
            <label for="rod">Rod Negatora</label>
            <md-select
              v-model.lazy="working_entry.negator.negator_rod"
              name="negator_rod"
              id="negator_rod"
              placeholder="Rod"
              :disabled="akter_locked"
            >
              <md-option value="M">M</md-option>
              <md-option value="Ž">Ž</md-option>
              <md-option value="NA">Nepoznato</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-100">
          <md-field>
            <label for="negator_financiranje">Izvor Financiranja</label>
            <md-select
              v-model.lazy="working_entry.negator.negator_financiranje"
              name="Izvor Financiranja"
              id="negator_financiranje"
              :disabled="akter_locked"
              
            >
              <md-option value=""></md-option>
              <md-option value="Poznato: Javna sredstva"
                >Poznato: Javna sredstva</md-option
              >
              <md-option value="Poznato: Donacije">Poznato: Donacije</md-option>
              <md-option value="Poznato: Komercijalno"
                >Poznato: Komercijalno</md-option
              >
              <md-option value="NA">Nepoznato</md-option>
              <md-option value="Nepoznato">Nepoznato</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-100">
          <md-field>
            <label for="negator_drzava">Država Negatora</label>
            <md-select
              v-model.lazy="working_entry.negator.negator_drzava"
              name="negator_drzava"
              id="negator_drzava"
              placeholder="Država Negatora"
              :disabled="akter_locked"
              
            >
              <md-option value=""></md-option>
              <md-option value="Bosna i Hercegovina"
                >Bosna i Hercegovina</md-option
              >
              <md-option value="Bosna i Hercegovina - RS"
                >Bosna i Hercegovina - RS</md-option
              >
              <md-option value="Bosna i Hercegovina - FBiH"
                >Bosna i Hercegovina - FBiH</md-option
              >
              <md-option value="Bosna i Hercegovina - BD"
                >Bosna i Hercegovina - BD</md-option
              >
              <md-option value="Republika Srbija">Republika Srbija</md-option>
              <md-option value="Crna Gora"
                >Crna Gora</md-option
              >
              <md-option value="Hrvatska"
                >Hrvatska</md-option
              >
              <md-option value="Ostale države iz regije"
                >Ostale države iz regije</md-option
              >
              <md-option value="Ostale države">Ostale države</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label for="negator_sektor">Sektor Negatora</label>
            <md-select
              v-model.lazy="working_entry.negator.negator_sektor"
              name="negator_sektor"
              id="negator_sektor"
              placeholder="Sektor Negatora"
              :disabled="akter_locked"
              
            >
              <md-option value=""></md-option>
              <md-option value="Javni (državni)">Javni (državni)</md-option>
              <md-option value="Komercijalni (privatni)"
                >Komercijalni (privatni)</md-option
              >
              <md-option value="Neprofitni">Neprofitni</md-option>
              <md-option value="NA">Nepoznato</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label for="negator_karakteristike">Karakteristike Negatora</label>
            <md-select
              v-model.lazy="working_entry.negator.negator_karakteristike"
              name="negator_karakteristike"
              id="negator_karakteristike"
              placeholder="Karakteristike Negatora"
              :disabled="akter_locked"
            >
              <md-option value=""></md-option>
              <md-option value="Zvaničnik/ca izvršne vlasti"
                >Zvaničnik/ca izvršne vlasti</md-option
              >
              <md-option value="Zvaničnik/ca zakonodavne vlasti"
                >Zvaničnik/ca zakonodavne vlasti</md-option
              >
              <md-option value="Zvaničnik/ca pravosuđe"
                >Zvaničnik/ca pravosuđe</md-option
              >
              <md-option value="Javni/a službenik/ca"
                >Javni/a službenik/ca</md-option
              >
              <md-option value="Privatno lice">Privatno lice</md-option>
              <md-option value="Javna ličnost">Javna ličnost</md-option>
              <md-option value="Institucija">Institucija</md-option>
              <md-option value="Organizacija">Organizacija</md-option>
              <md-option value="Neformalna grupa">Neformalna grupa</md-option>
              <md-option value="Medij">Medij</md-option>
              <md-option value="Religijska ustanova"
                >Religijska ustanova</md-option
              >
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-100">
          <md-field>
            <label>Uloga u Ratu Negatora</label>
            <md-input
              :disabled="akter_locked"
              v-model.lazy="working_entry.negator.negator_uloga_u_ratu"
            ></md-input>
          </md-field>
        </div>
      </div>

      <div class="md-layout-item md-size-100" v-if="platform_data_fetched">
        <md-toolbar :md-elevation="1">
          <span class="md-layout-item md-size-100 md-title md-aligment-top-left" style="text-align:left; padding-top:5px">Podaci o platformi</span>
          <div class="md-layout-item md-size-33">            
            <md-field>
              <label for="rod"
                >Odaberite platformu</label
              >
              <md-select v-model.lazy="working_entry.cin.cin_uuid_platforma">
                <md-option
                  v-for="item in platforms_list"
                  v-bind:key="item.id"
                  v-bind:value="item.id"
                  >{{ item.platforma_naziv }}</md-option
                >
              </md-select>
            </md-field>

          </div>
<div class="md-layout-item md-size-33">
          <md-switch v-model.lazy="platform_locked" disabled>
            <label v-if="platform_locked">Editovanje je trenutno ugašeno</label
            ><label v-else>Editovanje je trenutno upaljeno</label></md-switch
          >
</div>

          <div class="md-layout-item md-size-33">
            <md-button
              class="md-dense md-raised md-primary"
              v-on:click="add_new_platform"
              >Dodaj novu platformu</md-button
            >
          </div>
        </md-toolbar>

        <div class="md-layout-item md-size-100">
          <md-field>
            <input v-model.lazy="working_entry.platforma.platforma_naziv" class="md-input" type="text" required placeholder="Naziv Platforme" :disabled="platform_locked">          
          </md-field>
        </div>

        <div class="md-layout-item md-size-100">
          <md-field>
            <input v-model.lazy="working_entry.platforma.platforma_naziv" class="md-input" type="text" required placeholder="Opis Platforme" :disabled="platform_locked">          

          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label for="platforma_lokacija">Platforma Lokacija</label>
            <md-select
              v-model.lazy="working_entry.platforma.platforma_lokacija"
              name="platforma_lokacija"
              id="platforma_lokacija"
              placeholder="Platforma Lokacija"
              :disabled="platform_locked"
              
            >
              <md-option value=""></md-option>
              <md-option value="Bosna i Hercegovina"
                >Bosna i Hercegovina</md-option
              >
              <md-option value="Bosna i Hercegovina - RS"
                >Bosna i Hercegovina - RS</md-option
              >
              <md-option value="Bosna i Hercegovina - FBiH"
                >Bosna i Hercegovina - FBiH</md-option
              >
              <md-option value="Bosna i Hercegovina - BD"
                >Bosna i Hercegovina - BD</md-option
              >
              <md-option value="Republika Srbija">Republika Srbija</md-option>
              <md-option value="Crna Gora ili Hrvatska"
                >Crna Gora ili Hrvatska</md-option
              >
              <md-option value="Ostale države iz regije"
                >Ostale države iz regije</md-option
              >
              <md-option value="Ostale države">Ostale države</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label for="platforma_financiranje_sektor"
              >Izvor Financiranja Platforme</label
            >
            <md-select
              v-model.lazy="working_entry.platforma.platforma_financiranje_sektor"
              name="platforma_financiranje_sektor"
              id="platforma_financiranje_sektor"
              :disabled="platform_locked"
              
            >
              <md-option value=""></md-option>
              <md-option value="Poznato: Javna sredstva"
                >Poznato: Javna sredstva</md-option
              >
              <md-option value="Poznato: Donacije">Poznato: Donacije</md-option>
              <md-option value="Poznato: Komercijalno"
                >Poznato: Komercijalno</md-option
              >
              <md-option value="NA">Nepoznato</md-option>
              <md-option value="Nepoznato">Nepoznato</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label for="platforma_tip_medija">Tip Medija</label>
            <md-select
              v-model.lazy="working_entry.platforma.platforma_tip_medija"
              name="platforma_tip_medija"
              id="platforma_tip_medija"
              :disabled="platform_locked"
              
            >
              <md-option value=""></md-option>
              <md-option value="Javna novinska agencija"
                >Javna novinska agencija</md-option
              >
              <md-option value="Javna RTV">Javna RTV</md-option>
              <md-option value="Komercijalna novinska agencija"
                >Komercijalna novinska agencija</md-option
              >
              <md-option value="Komercijalni medij"
                >Komercijalni medij</md-option
              >
              <md-option value="Anonimna web stranica"
                >Anonimna web stranica</md-option
              >
              <md-option value="Društvene mreže">Društvene mreže</md-option>
              <md-option value="Naučni rad">Naučni rad</md-option>
              <md-option value="Knjiga">Knjiga</md-option>
              <md-option value="Ostalo">Ostalo</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <h3 class="danger" v-if="show_requireds">Negator, Platforma i Datum negiranja su obavezna polja, molim Vas unesite ih ako već niste.</h3>
      </div>
    <div class="md-layout">
    <md-button
    type="submit"
        class="md-dense md-raised md-primary submit"
        >Dodaj</md-button
    >
    </div>
    </form>
    <md-dialog :md-active.sync="show_modal_success">
      <md-dialog-title>Uspješan unos</md-dialog-title>

        <div class="md-layout">
          <p class="md-layout-item">Unos je uspješno spašen, hvala vam na vašem doprinosu!</p>
        </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="reload_page">Zatvoriti</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="show_modal_error">
      <md-dialog-title>Greška pri unosu!</md-dialog-title>

        <div class="md-layout">
          <p class="md-layout-item">{{aktivni_error_text}}</p>
        </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_modal_error = false">Zatvoriti</md-button>
      </md-dialog-actions>
    </md-dialog>
</div>
  </div>
  
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars



export default {
  name: "AddForm",
  methods: {
    reload_page(event){
      location.reload()
    },
    add_new_negator(event) {
      this.working_entry.negator = {
        uuid_negator: "",
        negator_financiranje: "",
        negator_drzava: "",
        negator_ime_naziv: "",
        negator_oblast: "",
        negator_rod: "",
        negator_sektor: "",
        negator_karakteristike: "",
        negator_uloga_u_ratu: "",
      };
      this.akter_locked = false;
      this.working_entry.cin.cin_uuid_negator = "";
    },
    add_new_platform(event) {
      this.working_entry.platforma = {
        platforma_lokacija: "",
        platforma_opis: "?",
        platforma_tip_medija: "",
        uuid_platforma: "",
        platforma_financiranje_sektor: "",
        platforma_naziv: "",
      };
      this.platform_locked = false;
      this.working_entry.cin.cin_uuid_platforma = "";
    },
    async add_cin(event) {
      const token = await this.$auth.getTokenSilently()
      if(this.working_entry.platforma.platforma_naziv == "" || this.working_entry.negator.negator_ime_naziv == "" || this.working_entry.cin.cin_datum == "")
      {
        this.show_requireds = true
        return null
      }

      this.show_requireds = false

      var data_prepared = {
        cin: {},
        negator: {},
        platforma: {},
      };
      if (this.working_entry.cin.cin_uuid_negator == "") {
        data_prepared.negator = this.working_entry.negator;
      }
      if (this.working_entry.cin.cin_uuid_platforma == "") {
        data_prepared.platforma = this.working_entry.platforma;
      }
      data_prepared.cin = this.working_entry.cin;

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`  

      };

      axios
        .post(
          "https://hxy5npx7g6.execute-api.eu-west-1.amazonaws.com/staging/cin",
          data_prepared,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.show_modal_success = true
          } 
          console.log(response);
        })
        .catch((error) => {
          
          console.log(error);
          if (error.response.status == "409")
          {
            this.aktivni_error_text = this.naming_conflict;            
            this.show_modal_error = true;
          } else{
            this.aktivni_error_text = this.opci_error_text;   
            this.show_modal_error = true;         
          }
        });
    },
    async get_token() {
      return await this.$auth.getTokenSilently();
    }
        
  },
  data: () => ({
    initial: "Initial Value",
    show_requireds: false,
    type: null,
    token: null,
    apiMessage: "",
    naming_conflict: "Negator ili platforma kojeu pokušavate unijeti već postoji u bazi podataka, molim vas odaberite već postojeće iz dropdowna.",
    opci_error_text:"Došlo je do neočekivane greške tokom unosa podataka, molim vas napravite screenshot i pošaljite nam ga da možemo provjeriti u čemu je problem. Izvinjavamo se zbog smetnje",
    aktivni_error_text: "",
    show_modal_error: false,
    show_modal_success: false,
    data_fetched: false,
    withLabel: null,
    inline: null,
    number: null,
    textarea: null,
    autogrow: null,
    disabled: null,
    working_cin: {},
    working_negator: {},
    working_platforma: {},
    active_entry: {},
    working_entry: {
      cin: {
        cin_nacin_negiranja: "",
        cin_datum: "",
        cin_uuid_platforma: "",
        cin_uuid_negator: "",
        cin_drzava: "",
        cin_naslov: "",
        cin_link: "",
        cin_citat_opis: "",
      },
      negator: {
        negator_financiranje: "",
        negator_drzava: "",
        negator_ime_naziv: "",
        negator_oblast: "",
        negator_rod: "",
        negator_sektor: "",
        negator_karakteristike: "",
        negator_uloga_u_ratu: "",
        negator_opis: "",
      },
      platforma: {
        platforma_lokacija: "",
        platforma_opis: "",
        platforma_tip_medija: "",
        platforma_financiranje_sektor: "",
        platforma_naziv: "",
      },
    },
    akter_locked: true,
    platform_locked: true,
    all_platforms: {},
    all_negatori: {},
    adding_new_negator: false,
    adding_new_platform: false,
    new_negator: {
      uuid_negator: "",
      negator_financiranje: "",
      negator_drzava: "",
      negator_ime_naziv: "",
      negator_oblast: "",
      negator_rod: "",
      negator_sektor: "",
      negator_karakteristike: "",
      negator_uloga_u_ratu: "",
    },
    new_platform: {
      platforma_lokacija: "",
      platforma_opis: "?",
      platforma_tip_medija: "",
      uuid_platforma: "",
      platforma_financiranje_sektor: "",
      platforma_naziv: "",
    },
    platforms_dict: {},
    negators_dict: {},
    platforms_list: [],
    negators_list: [],
    negator_data_fetched: false,
    platform_data_fetched: false,
  }),
  mounted: async function () {
    const token = await this.get_token()

    axios
      .get("https://hxy5npx7g6.execute-api.eu-west-1.amazonaws.com/negatori",
      {
        headers: {
          Authorization: `Bearer ${this.token}`  
        }
      })
      .then(((response) => {
          this.all_negatori = response.data.Items
        }))
        .then((response) => {
        
        let new_negator_dict = {};
        this.all_negatori.forEach(function (item) {
            new_negator_dict[item.uuid_negator] = item;
        });
        this.negatori_dict = new_negator_dict;
        })
        .then((response) => {
        let new_negatori_list = [];
        this.all_negatori.forEach(function (item) {
          var q = {
            id: item.uuid_negator,
            negator_ime_naziv: item.negator_ime_naziv,
          };
          new_negatori_list.push(q);
        });
        new_negatori_list.sort((a, b) =>
          a.negator_ime_naziv > b.negator_ime_naziv
            ? 1
            : b.negator_ime_naziv > a.negator_ime_naziv
            ? -1
            : 0
        );
        this.negators_list = new_negatori_list;})
        .then((response) => {this.negator_data_fetched = true;})

    axios
      .get("https://hxy5npx7g6.execute-api.eu-west-1.amazonaws.com/platforme",
      {
        headers: {
          Authorization: `Bearer ${token}`  
        }
      })
      .then((response) => {
        this.all_platforms =  response.data.Items;
        })
      .then((response) => {
        
        let new_platform_dict = {};
        this.all_platforms.forEach(function (item) {
          new_platform_dict[item.uuid_platforma] = item;
        });
        this.platforms_dict = new_platform_dict;
        })
      .then((response) => {
        
        let new_platforms_list = [];
        this.all_platforms.forEach(function (item) {
          var q = {
            id: item.uuid_platforma,
            platforma_naziv: item.platforma_naziv,
          };
          new_platforms_list.push(q);
        });
        new_platforms_list.sort((a, b) =>
          a.platforma_naziv > b.platforma_naziv
            ? 1
            : b.platforma_naziv > a.platforma_naziv
            ? -1
            : 0
        );
        this.platforms_list = new_platforms_list;
        })
      .then((response)  =>{
        this.platform_data_fetched = true;
        });
  },
  created: async function(){
    this.token = await this.get_token()
  },
  computed: {
    active_negator: function () {
      return this.working_entry.cin.cin_uuid_negator;
    },
    active_platform: function () {
      return this.working_entry.cin.cin_uuid_platforma;
    },
  },
  watch: {
    active_negator: function () {
      this.working_entry.negator = this.negatori_dict[this.active_negator];
    },
    active_platform: function () {
      this.working_entry.platforma = this.platforms_dict[this.active_platform];
    },
  },
};
</script>
<style >

</style>