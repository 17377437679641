<template>
  <div id="app">
  <div class="content md-layout md-alignment-top-center md-theme-default">
    <router-view/>
  </div>
  </div>
</template>
<script>
    export default {
        name: 'app',
        created () {
            document.title = "Negiranje Genocida";
        }
    }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgb(238, 238, 238)
}
body{
  background-color: rgb(238, 238, 238)
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
